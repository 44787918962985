@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply transition ease-in-out duration-500 appearance-none text-sm px-3 py-2 border border-gray-150  bg-white focus:ring-1 focus:ring-blue-100 focus:border-blue-800 focus:outline-none;
  }
  [type="checkbox"],
  [type="radio"] {
    @apply transition ease-in-out duration-500 border-gray-150 rounded text-blue-600 text-sm;
  }
}

body {
  @apply bg-gray-25;
}

.float-label {
  @apply absolute -top-2 left-2 text-sm text-gray-700 px-2 font-medium rounded-full;
}

.float-input {
  @apply appearance-none px-4 pt-4 pb-3 w-full  border border-gray-150 text-sm focus:outline-none focus:ring-0 focus:border-blue-600;
}

.sidebar-mobile {
  @apply fixed top-0 bottom-0 left-0 w-full;
  z-index: 2147483004;
}

.wrapper {
  @apply max-w-screen-xl mx-auto p-4 md:p-6 lg:p-8 xl:p-10 grid gap-3 overflow-auto pt-14 sm:pt-0;
}

.sidebar {
  @apply flex flex-col fixed justify-between items-stretch bg-black border-r border-gray-100  text-sm text-blue-700 h-full w-full sm:w-20 lg:w-64 2xl:w-72 flex-shrink-0;
}

.flyout-item {
  @apply flex items-center space-x-1 hover:bg-gray-800 text-gray-200 px-4 py-2 font-normal text-xs;
}

.content {
  @apply p-4 lg:p-6 xl:p-8 overflow-auto;
}

.custom-tab {
  @apply flex items-center space-x-1 px-3 py-1.5 hover:text-indigo-500 text-gray-600 font-medium text-sm bg-white;
}

.custom-tab-active {
  @apply font-bold text-white decoration-slice hover:text-indigo-500 bg-gray-800;
}

.card {
  @apply border border-gray-100  p-3 bg-white;
}

.stat-card {
  @apply border border-gray-100  p-5 bg-white;
}

.card-hover {
  @apply hover:border hover:border-black;
}

.btn {
  @apply transition duration-500 ease-in-out px-3 py-2 flex border border-transparent items-center justify-center text-center space-x-1  font-medium text-sm;
}

.btn-cyan {
  @apply bg-blue-600 text-cyan-50 hover:bg-blue-800;
}

.btn-rose {
  @apply bg-rose-600 text-rose-50 hover:bg-rose-800;
}

.btn-teal {
  @apply bg-teal-600 text-teal-50 hover:bg-teal-800;
}

.btn-white {
  @apply bg-gray-100 text-gray-700 hover:text-black hover:border-black;
}

.btn-gray {
  @apply bg-gray-700 text-white hover:bg-black;
}

.green {
  @apply hover:bg-green-dark hover:text-green-light bg-green-main text-green-dark;
}

.blue {
  @apply bg-blue-600 text-blue-50 hover:bg-blue-dark hover:text-blue-light;
}

.tab {
  @apply px-3 py-2 border-b  font-medium text-sm text-gray-600 hover:bg-gray-150  border-transparent;
}

.tab-blue {
  @apply bg-blue-light text-blue-dark;
}

.tab-active {
  @apply px-3 py-2 border-b-1.5 border-blue-600 text-black font-medium text-sm;
}

.blue {
  @apply bg-blue-900 text-blue-50;
}

.title {
  @apply font-bold text-base;
}

.red {
  @apply bg-red-100 text-red-700 hover:bg-red-700 hover:text-red-100;
}

.reset {
  all: initial;
}

.flex-center {
  @apply flex items-center justify-center;
}

.flex-between {
  @apply flex items-center justify-between;
}

.spaced-x-1 {
  @apply flex items-center space-x-1;
}

.spaced-x-2 {
  @apply flex items-center space-x-3;
}

.spaced-x-3 {
  @apply flex items-center space-x-3;
}

.spaced-x-4 {
  @apply flex items-center space-x-4;
}

.spaced-x-5 {
  @apply flex items-center space-x-5;
}

.spaced-y-1 {
  @apply flex flex-col space-y-1;
}

.spaced-y-2 {
  @apply flex flex-col space-y-2;
}

.spaced-y-3 {
  @apply flex flex-col space-y-3;
}

.form-input {
  @apply appearance-none px-3 py-2 border bg-white focus:ring-1 focus:ring-cyan-100 focus:border-cyan-800 focus:outline-none;
}

.editor-input {
  @apply px-3 pb-2 pt-10 transition ease-in-out duration-500 appearance-none text-sm px-3 py-2 border border-gray-150  bg-white focus:ring-1 focus:ring-blue-100 focus:border-blue-800 focus:outline-none;
}

.form-label {
  @apply text-sm text-gray-700 font-medium;
}

.heading {
  @apply text-xl font-bold;
}

.h1 {
  @apply text-xl font-bold;
}

.h2 {
  @apply text-lg font-semibold;
}

.subheading {
  @apply text-gray-800 text-lg font-semibold;
}

.small {
  @apply text-gray-600 text-sm;
}

.tag {
  @apply px-2 py-1 text-xs rounded-full font-medium flex items-center space-x-1;
}

.tag-cyan {
  @apply bg-cyan-50 text-cyan-800;
}

.tag-green {
  @apply bg-indigo-50 text-indigo-800;
}

.tag-rose {
  @apply bg-gray-50 text-gray-800;
}

.tag-sky {
  @apply bg-sky-50 text-sky-800;
}

.tag .textarea {
  font-family: "europa", "sans-serif" !important;
}

.message-card {
  @apply w-full p-3 font-medium text-center  bg-cyan-50 text-cyan-900;
}

.error-card {
  @apply w-full p-3 font-medium text-center  bg-rose-50 text-rose-900;
}

.menu-item {
  @apply text-gray-600 p-1.5  border border-gray-150 shadow-sm;
}

.menu-item-active {
  @apply text-gray-900 p-1.5  border bg-gray-150;
}

.chat-header {
  @apply w-full bg-gray-50 border-b p-4;
}

.chat-title {
  @apply text-lg font-bold;
}

.chat-message {
  @apply px-3 py-2 mx-2 break-words overflow-hidden;
}

.chat-message-user {
  @apply rounded-l-2xl rounded-br-xl bg-blue-600 text-white ml-10 max-w-[512px];
}

.chat-message-other {
  @apply rounded-r-2xl rounded-bl-xl  bg-gray-150 mr-10 max-w-[512px];
}

.chat-card {
  @apply order-first flex-shrink-0 w-8 h-8 rounded-full text-base shadow-inner border-2 bg-cyan-600 text-white font-bold uppercase flex items-center justify-center text-center;
}

.chat-card-fuego {
  @apply order-first flex-shrink-0 w-8 h-8 rounded-full text-base shadow-inner border-2 bg-blue-600 text-white font-bold uppercase flex items-center justify-center text-center;
}

.chat-card-active {
  @apply flex-shrink-0 w-8 h-8 rounded-full text-base shadow-inner border-2 bg-blue-800 text-white font-bold uppercase flex items-center justify-center text-center;
}

.chat-user-image {
  @apply flex-shrink-0 w-8 h-8 rounded-full text-base shadow-inner border-2 font-bold uppercase flex items-center justify-center text-center;
}

.chat-message-wrapper {
  @apply flex-grow flex flex-col items-stretch space-y-1 overflow-auto px-2 py-1;
}

.chat-user-name {
  @apply flex items-center text-xs text-black rounded-full;
}

.chat-date {
  @apply my-3 text-xs px-2 py-1 mx-auto bg-gray-500 text-white font-medium rounded-full;
}

th {
  @apply font-bold text-gray-600 text-xs uppercase text-left;
}

.intercom-lightweight-app {
  z-index: 10 !important;
}

.animate-move {
  animation: move 1s infinite;
}

@keyframes move {
  0%,
  100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.smooth {
  @apply transition duration-300 ease-in-out;
}
